import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Icon, IconName, ThemeTypes, Typography } from 'components';
import { useRouter } from 'next/router';
import NextLink from '../NextLink';
import { Box } from '@mui/material';
import classnames from 'classnames';
import { getIsLinkActive } from './Header.utils';

const useStyles = makeStyles(
	(theme) => ({
		linkContainer: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			gap: theme.spacing(1),
			whiteSpace: 'nowrap',
			backgroundColor: 'initial',
			padding: theme.spacing(0.5, 1.5),
			borderRadius: 10,
			transition: theme.transitions.create('background-color'),
			cursor: 'pointer',
			height: theme.spacing(5),

			'&$withIcon': {
				padding: theme.spacing(0.5, 2, 0.5, 1.5)
			},

			'&$isActive': {
				backgroundColor: '#019ABC1A',

				'& > $linkText': {
					color: theme.palette.primary.main,
					fontWeight: 600
				}
			},

			'&:hover': {
				backgroundColor: '#0000000A',

				'& > $childLinksContainer': {
					opacity: 1,
					pointerEvents: 'auto'
				}
			}
		},
		linkText: {
			fontWeight: 400,
			zIndex: 1,
			letterSpacing: '0px'
		},
		childLinksContainer: {
			position: 'absolute',
			left: 0,
			bottom: 0,
			paddingTop: theme.spacing(1),
			transform: 'translate(0, 100%)',
			transition: theme.transitions.create('opacity'),
			opacity: 0,
			pointerEvents: 'none'
		},
		childLinksInnerContainer: {
			background: theme.palette.common.white,
			border: `1px solid ${theme.palette.body.light}`,
			borderRadius: 10,
			padding: theme.spacing(0.5),

			'& > $linkContainer': {
				position: 'relative',
				backgroundColor: 'transparent',
				height: theme.spacing(6),
				marginBottom: 3,
				paddingInline: theme.spacing(1),
				...theme.border({ width: 1, color: theme.palette.body['200'], kind: 'top' }),
				transition: theme.transitions.create('border-color'),

				'&::before': {
					content: '""',
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					background: `linear-gradient(90deg, #F2F5F8 0%, ${theme.palette.common.white} 100%)`,
					transition: theme.transitions.create('opacity'),
					opacity: 0,
					borderRadius: 6
				},

				'&:first-child': {
					borderWidth: 0
				},

				'&:hover,&$isActive': {
					borderColor: 'transparent',

					'&+$linkContainer': {
						borderColor: 'transparent'
					},

					'&::before': {
						opacity: 1
					}
				}
			}
		},
		icon: {
			width: 24,
			height: 24,
			color: theme.palette.primary.main,
			zIndex: 1
		},
		isActive: {},
		withIcon: {}
	}),
	{ name: 'HeaderLink' }
);

type HeaderLinkProps = {
	href: string | null;
	childLinks?: Array<Omit<HeaderLinkProps, 'childLinks'>> | undefined;
	localeId: string;

	iconName?: IconName;
	iconColor?: ThemeTypes.AllColors;
	iconColorVariant?: ThemeTypes.AllColorsVariants;

	color?: ThemeTypes.AllColors;
	colorVariant?: ThemeTypes.AllColorsVariants;
};

export const HeaderLink = (props: HeaderLinkProps) => {
	const {
		href,
		childLinks = [],
		localeId,
		iconName,
		iconColor,
		iconColorVariant,
		color = 'primary',
		colorVariant = 'main'
	} = props;
	const { pathname, query } = useRouter();

	const isActive = [href, ...childLinks.map((link) => link.href)].some((href) =>
		getIsLinkActive({ href, pathname, query })
	);

	const RootComponent = href
		? (props: { className?: string }) => <NextLink {...props} href={href} />
		: Box;

	const classes = useStyles();
	return (
		<RootComponent
			className={classnames(classes.linkContainer, {
				[classes.isActive]: isActive,
				[classes.withIcon]: !!iconName
			})}
		>
			{iconName ? (
				<Icon
					icon={iconName}
					className={classes.icon}
					color={iconColor || color}
					colorVariant={(iconColor && iconColorVariant) || colorVariant}
				/>
			) : null}
			<Typography
				variant="textXSmall"
				color={color}
				colorVariant={colorVariant}
				className={classes.linkText}
				localeId={localeId}
			/>
			{childLinks.length > 0 ? (
				<Box className={classes.childLinksContainer}>
					<Box className={classes.childLinksInnerContainer}>
						{childLinks.map((link) => (
							<HeaderLink {...link} key={link.href} />
						))}
					</Box>
				</Box>
			) : null}
		</RootComponent>
	);
};
